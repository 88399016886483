import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import '../assets/scss/components/_banner.scss';

const Banner = () => {
  return (
    <a href="https://www.quranplan.com/" target="_blank" className="Banner-cont" rel="noopener noreferrer">
      <StaticImage
        src="../assets/images/quranplan-500x125.png"
        alt="banner"
        layout="constrained"
        width={500} // Image Size 500px x 125px
      />
    </a>
  );
};

export default Banner;
